import React from 'react'

const Icon = ({ style }) => (
  <svg viewBox="0 0 119 100" style={style}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <rect x="0" y="0" width="119" height="100" />
        <g transform="translate(44.000000, 10.000000)">
          <path
            d="M14.9682512,19.7135701 C17.792662,19.7135701 20.0831701,17.4255048 20.0831701,14.5992005 C20.0831701,11.7728962 17.792662,9.4848309 14.9682512,9.4848309 C12.144939,9.4848309 9.85443091,11.7728962 9.85443091,14.5992005 C9.85443091,17.4255048 12.144939,19.7135701 14.9682512,19.7135701 M14.875812,24.1770199 C20.2181622,24.1770199 24.54662,19.8485622 24.54662,14.5072906 C24.54662,9.16601898 20.2181622,4.83540399 14.875812,4.83540399 C9.53561899,4.83540399 5.205004,9.16601898 5.205004,14.5072906 C5.205004,19.8485622 9.53561899,24.1770199 14.875812,24.1770199 M0.369600013,14.5992005 C0.369600013,6.53611614 6.90463473,-2.66453526e-14 14.9677191,-2.66453526e-14 C23.0318849,-2.66453526e-14 29.568001,6.53611614 29.568001,14.5992005 C29.568001,22.660122 23.0318849,29.198401 14.9677191,29.198401 C6.90463473,29.198401 0.369600013,22.660122 0.369600013,14.5992005"
            fill="#CC0001"
          />
          <g transform="translate(0.000000, 31.416001)" fill="#9B9B9B">
            <rect
              transform="translate(15.708001, 20.512801) rotate(45.000000) translate(-15.708001, -20.512801) "
              x="9.24000032"
              y="19.5888007"
              width="12.9360004"
              height="1.84800006"
              rx="0.924000032"
            />
            <rect
              transform="translate(14.599201, 20.512801) scale(-1, 1) rotate(45.000000) translate(-14.599201, -20.512801) "
              x="8.13120028"
              y="19.5888007"
              width="12.9360004"
              height="1.84800006"
              rx="0.924000032"
            />
            <rect
              transform="translate(17.001601, 31.600801) rotate(40.000000) translate(-17.001601, -31.600801) "
              x="7.02240024"
              y="30.6768011"
              width="19.9584007"
              height="1.84800006"
              rx="0.924000032"
            />
            <rect
              transform="translate(12.936000, 31.600801) scale(-1, 1) rotate(40.000000) translate(-12.936000, -31.600801) "
              x="2.9568001"
              y="30.6768011"
              width="19.9584007"
              height="1.84800006"
              rx="0.924000032"
            />
            <path
              d="M2.40240008,42.1344014 L24.2088008,42.1344014 C24.719112,42.1344014 25.1328009,42.5480904 25.1328009,43.0584015 C25.1328009,43.5687126 24.719112,43.9824015 24.2088008,43.9824015 L2.40240008,43.9824015 C1.89208896,43.9824015 1.47840005,43.5687126 1.47840005,43.0584015 C1.47840005,42.5480904 1.89208896,42.1344014 2.40240008,42.1344014 Z"
              transform="translate(13.305600, 43.058401) scale(-1, 1) rotate(30.000000) translate(-13.305600, -43.058401) "
            />
            <path
              d="M5.7288002,42.1344014 L27.5352009,42.1344014 C28.0455121,42.1344014 28.459201,42.5480904 28.459201,43.0584015 C28.459201,43.5687126 28.0455121,43.9824015 27.5352009,43.9824015 L5.7288002,43.9824015 C5.21848907,43.9824015 4.80480016,43.5687126 4.80480016,43.0584015 C4.80480016,42.5480904 5.21848907,42.1344014 5.7288002,42.1344014 Z"
              transform="translate(16.632001, 43.058401) rotate(30.000000) translate(-16.632001, -43.058401) "
            />
            <rect
              transform="translate(8.870400, 25.687201) rotate(15.000000) translate(-8.870400, -25.687201) "
              x="7.02240024"
              y="-0.369600013"
              width="3.69600013"
              height="52.1136018"
              rx="1.84800006"
            />
            <rect
              transform="translate(21.436801, 25.687201) scale(-1, 1) rotate(15.000000) translate(-21.436801, -25.687201) "
              x="19.5888007"
              y="-0.369600013"
              width="3.69600013"
              height="52.1136018"
              rx="1.84800006"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Icon
