import React from 'react'

const Icon = ({ style }) => (
  <svg viewBox="0 0 114 125" style={style}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(1.000000, 1.000000)" fill="#FFFFFF">
        <g>
          <path
            d="M47.622576,111.674893 L32.9842993,121.905155 C31.9307323,122.641462 30.668558,123.019968 29.3836753,122.984925 C26.0711984,122.894583 23.4591419,120.136054 23.5494838,116.823577 L23.9483566,102.198496 C9.46960537,92.0480511 0,75.2075913 0,56.1494828 C0,25.1389798 25.072054,5.68434189e-14 56,5.68434189e-14 C86.927946,5.68434189e-14 112,25.1389798 112,56.1494828 C112,87.1599859 86.927946,112.298966 56,112.298966 C53.1529041,112.298966 50.3554325,112.085931 47.622576,111.674893 Z"
            strokeOpacity="0.796956949"
            stroke="#FFFFFF"
            strokeWidth="1.80000007"
            fillOpacity="0.6"
          />
          <path d="M56.497,71.207 C64.21,71.207 70.465,64.958 70.465,57.239 C70.465,49.52 64.21,43.271 56.497,43.271 C48.787,43.271 42.532,49.52 42.532,57.239 C42.532,64.958 48.787,71.207 56.497,71.207 M56.497,84.395 C71.356,84.395 83.395,72.356 83.395,57.5 C83.395,42.644 71.356,30.599 56.497,30.599 C41.644,30.599 29.599,42.644 29.599,57.5 C29.599,72.356 41.644,84.395 56.497,84.395 M16,57.5 C16,35.132 34.129,17 56.497,17 C78.868,17 97,35.132 97,57.5 C97,79.862 78.868,98 56.497,98 C34.129,98 16,79.862 16,57.5" />
        </g>
      </g>
    </g>
  </svg>
)

export default Icon
