import React from "react"
import { Grid, Typography } from "@material-ui/core"
const Projects = () => {
  return (
    <Grid container>
      <Typography>Projects</Typography>
    </Grid>
  )
}

export default Projects
