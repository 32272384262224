import React from 'react'

const Icon = ({ style }) => (
  <svg viewBox="0 0 363 351" style={style}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-53.000000, -12.000000)" fillRule="nonzero">
        <g>
          <g>
            <g>
              <path
                d="M293.514858,236.785739 L293.514858,168.785739 C315.606248,168.785739 333.514858,184.008058 333.514858,202.785739 C333.514858,221.563421 315.606248,236.785739 293.514858,236.785739 Z M88.6203213,247.899598 C87.5324333,247.911447 86.4416155,247.927631 85.3478678,247.948151 C62.0760153,248.384757 42.8565082,229.873137 42.4198972,206.601284 C42.4149538,206.337795 42.412482,206.074265 42.412482,205.81073 C42.412482,181.356397 61.3091341,161.05875 85.7010563,159.312752 C168.90092,153.357205 235.09347,129.643447 284.278706,88.171478 C284.673246,170.853667 284.046365,232.131426 284.278706,318.721271 C242.558738,277.481632 188.67261,254.246279 122.620322,249.015212 L122.620322,253.400966 L137.457926,274.897137 C138.08539,275.806182 137.857122,277.051769 136.948077,277.679232 C136.625296,277.902029 136.244131,278.025005 135.852003,278.032858 L122.620322,278.297868 L122.620322,324.176755 C122.620322,328.595033 119.0386,332.176755 114.620322,332.176755 L96.6203217,332.176755 C92.2020433,332.176755 88.6203213,328.595033 88.6203213,324.176755 L88.6203213,247.899598 Z"
                fill="#99A1AA"
                transform="translate(187.963670, 210.174116) rotate(-30.000000) translate(-187.963670, -210.174116) "
              />
              <path
                d="M376.134896,209.097019 L357.282658,209.097019 L389.466579,161.203557 C389.679411,160.886839 390.06948,160.739665 390.43848,160.836857 C390.917007,160.962897 391.202753,161.452995 391.076713,161.931522 L381.295298,199.067805 L393.282658,199.067805 L375.943294,242.897233 L360.171856,242.897233 L376.134896,209.097019 Z"
                fill="#4EBB5B"
                transform="translate(375.282658, 201.852240) rotate(94.000000) translate(-375.282658, -201.852240) "
              />
              <path
                d="M365.499011,106.660414 L346.646773,106.660414 L378.830695,58.7669522 C379.043526,58.4502345 379.433596,58.3030605 379.802596,58.4002522 C380.281122,58.5262925 380.566869,59.0163906 380.440829,59.494917 L370.659413,96.6312 L382.646773,96.6312 L365.30741,140.460629 L349.535971,140.460629 L365.499011,106.660414 Z"
                fill="#FBB64B"
                transform="translate(364.646773, 99.415636) rotate(53.000000) translate(-364.646773, -99.415636) "
              />
              <path
                d="M295.034339,52.6314041 L276.182101,52.6314041 L308.366022,4.73794214 C308.578854,4.42122444 308.968923,4.27405034 309.337923,4.37124214 C309.81645,4.49728234 310.102196,4.98738044 309.976156,5.46590684 L300.194741,42.6021902 L312.182101,42.6021902 L294.842737,86.431618 L279.071299,86.431618 L295.034339,52.6314041 Z"
                fill="#EB343B"
                transform="translate(294.182101, 45.386625) rotate(21.000000) translate(-294.182101, -45.386625) "
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Icon
